import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { minimumAge } from '../constants/viewConstants';
import { postShowOverlay } from '../services/voiceChatService';

function LegalEnablePage({ translate, onHide, heading, icon, bodyText, buttonStack }) {
  const [checked, setChecked] = useState(false);
  return (
    <React.Fragment>
      <Modal.Header>
        <div className='email-upsell-title-container'>
          <button type='button' className='email-upsell-title-button' onClick={onHide}>
            <span className='close icon-close' />
          </button>
          <Modal.Title id='contained-modal-title-vcenter'>{translate(heading)}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {icon && <div className={icon} />}
        <ul className='content-list'>
          {bodyText.map(text => (
            <li>
              {translate(text, {
                age: minimumAge
              })}
            </li>
          ))}
        </ul>
        <div className='checkbox checkbox-container'>
          <input
            id='isShowOverlayChecked'
            type='checkbox'
            checked={checked}
            onClick={() => {
              setChecked(!checked);
              postShowOverlay(checked);
            }}
          />
          <label htmlFor='isShowOverlayChecked'>{translate('Label.DontShowAgain')}</label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {buttonStack.map(button => (
          <span>
            <Button
              className={
                button.type === 'primary'
                  ? 'btn-primary-md primary-link button-stack-button'
                  : 'btn-secondary-md secondary-link button-stack-button'
              }
              onClick={
                button.type === 'primary' ? button.callback : () => button.callback(checked)
              }>
              {translate(button.text)}
            </Button>
          </span>
        ))}
      </Modal.Footer>
    </React.Fragment>
  );
}

LegalEnablePage.propTypes = {
  translate: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  bodyText: PropTypes.arrayOf(PropTypes.string).isRequired,
  buttonStack: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string,
      callback: PropTypes.func
    })
  ).isRequired
};

export default LegalEnablePage;
